$white: var(--white-color);
$default: var(--default-color);
$black: var(--black-color);
$titleColor: var(--title-color);
$bg: var(--bg-color);
$accent: var(--accent-color);
$grey: var(--grey-color);
$red: var(--red-color);
$bgGrad: var(--bg-grad);
$mainFont: var(--main-font);
$interFont: var(--inter-font);
:root {
	--white-color: #ffffff;
	--default-color: #2a2a39;
	--bg-color: #e5f2fd;
	--accent-color: #1f5aab;
	--title-color: #2a353f;
	--black-color: #000000;
	--grey-color: #d3dde9;
	--red-color: #c72727;
	--main-font: 'ALSHauss', sans-serif;
	--inter-font: 'Inter', sans-serif;
	--bg-grad: linear-gradient(0deg, #7497cd 0%, #0e4da4 100%);
}

$big-r: 1580;
$desm-r: 1280;
$tab-r: 1024;
$mtab-r: 992;
$stab-r: 768;
$bmob-r: 576;
@mixin adpt($width) {
	@media (max-width: #{$width}px) {
		@content;
	}
}

@mixin adpt-mob($width) {
	@media (min-width: #{$width}px) {
		@content;
	}
}

@include adpt($bmob-r) {
}
