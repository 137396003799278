html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

ul,
ol {
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  transition: color 0.3s, opacity 0.3s;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-weight: inherit;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  color: inherit;
  border-radius: 0;
  background-color: inherit;
  padding: 0;
  border: none;
}

button {
  border: none;
  cursor: pointer;
}

::selection {
  background: $accent;
  color: $white;
}

::-moz-selection {
  background: $accent;
  color: $white;
}

::-webkit-selection {
  background: $accent;
  color: $white;
}


@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }

  20% {
    opacity: 1;
  }

  25% {
    opacity: 0.3;
  }

  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }

  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }

  100% {
    opacity: 0.9;
  }
}

@keyframes error {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }


  100% {
    opacity: 1;
  }
}