.login {
	background: $white;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 50px;

	&__inner {
		max-width: 520px;
		width: 100%;
	}

	&__title {
		font-weight: 400;
		text-align: center;
		font-size: 20px;
		margin-bottom: 40px;
		display: block;
	}

	&__inner-input {
		padding: 30px 0 40px;

		& .input:not(:last-child) {
			margin-bottom: 12px;
		}
	}

	&__btn {
		width: 100%;
	}

	&__bottom {
		margin-top: 8px;
	}

	&__link-wrap {
		display: flex;
		align-items: center;
		font-size: 16px;
		justify-content: center;
	}
	&__link {
		color: #5c73db;
		opacity: 0.8;
		margin-left: 3px;
		text-decoration: underline;

		&:hover {
			opacity: 1;
		}
	}
}
