.system {
	&__title {
		font-weight: 500;
		font-size: 22px;
		margin-bottom: 10px;
	}
	&__list {
		font-size: 16px;
		margin-bottom: 30px;
	}
}
