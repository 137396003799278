.settings {
	&__email {
		background-color: rgba(255, 255, 255, 0.7);
		backdrop-filter: blur(5px);
		border-radius: 20px;
		padding: 20px;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	}
	&__email-inputs-inner {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 10px;
		max-width: 700px;
	}
	&__email-block-title {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 15px;
	}
	&__email-block {
		margin-bottom: 30px;
		&:first-child {
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid $default;
		}
	}
	&__email-row {
		display: flex;
		& > * {
			flex: 1;
			&:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
	&__email-title {
		margin-bottom: 25px;
		text-align: center;
	}
	&__email-lable {
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 1px;
		display: block;
	}
}
