.terminals {
	flex: 1;

	&__list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 35px;
		@include adpt($big-r) {
			grid-template-columns: 1fr 1fr;
		}
	}
	&__operators-name {
		font-size: 14px;
		margin-bottom: 1px;
	}
	&__operators {
		margin-bottom: 0;
	}
	&__operators-item {
		font-size: 14px;
		&._accent {
			color: red;
		}
	}
	&__item-status {
		position: absolute;
		top: 24px;
		right: 25px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: rgba(255, 0, 0, 1);
		z-index: 2;
		&._active {
			background-color: rgb(51, 238, 91);
		}
	}
	&__item {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		background-color: $white;
		padding: 22px 15px;
		border-radius: 15px;
		position: relative;
		overflow: hidden;
		border: 1px solid $accent;
		&._active {
			//background-color: rgba(40, 255, 86, 0.2);
			//border-color: rgb(51, 238, 91);
		}
		&._offline {
			//background-color: rgba(255, 0, 0, 0.1);
			//border-color: rgba(255, 0, 0, 1);
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(255, 255, 255, 0.5);
				top: 0;
				left: 0;
			}
		}
	}

	&__left {
		flex: 1;
	}
	&__right {
		max-width: 280px;
		width: 100%;
		flex: 1;
		margin-left: 30px;
	}

	&__name {
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 10px;
		color: $accent;

		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@include adpt($big-r) {
			font-size: 16px;
		}
		&:hover {
			color: #5c73db;
		}
	}
	&__info-item {
		display: flex;
		font-size: 14px;
		align-items: flex-start;
		line-height: 16px;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	&__info-icon {
		width: 16px;
		height: 16px;
		object-fit: contain;
		margin-right: 10px;
	}
	&__add {
		margin-bottom: 55px;
	}
	&__form-add-bottom {
		margin-top: 40px;
		display: flex;
		justify-content: flex-end;
		& > *:not(:last-child) {
			margin-right: 15px;
		}
	}

	&__manage-title {
		margin-bottom: 10px;
	}

	&__info {
		display: flex;
		flex-direction: column;
		font-size: 15px;
	}

	&__info-title {
		margin-right: 5px;
		font-weight: 600;
	}
	&__devices {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 1px 20px;

		& .terminal__device {
			border: none;
			padding: 0;
			position: relative;
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
		& .terminal__device-title-wrap {
			margin: 0;
			display: flex;
			font-size: 14px;
			align-items: flex-end;
		}
		& .terminal__device-status {
			margin-left: 5px;
			color: red;
			&._active {
				color: green;
			}
		}
	}
	&__center {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__center-left {
		margin-right: 20px;
	}

	&__status {
		display: flex;
		&._active {
			& span:nth-child(2) {
				color: green;
			}
		}
		& span:nth-child(2) {
			color: red;
		}
		& span:first-child {
			margin-right: 5px;
		}
		&-title {
			font-size: 16px;
			font-weight: 600;
		}
	}

	&__link {
		display: flex;
		align-items: center;
		position: relative;
		justify-content: center;
		margin-top: 20px;
		padding: 8px;
		font-size: 13px;
		background: $bgGrad;
		background-size: 120%;
		position: relative;
		border: none;
		height: 48px;
		width: 100%;
		overflow: hidden;
		border-radius: 12px;

		& > * {
			position: relative;
			z-index: 1;
		}
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, #7497cd 15%, #0e4da4 100%);
			opacity: 0;
			z-index: 0;
			transition: opacity 0.3s;
		}
		&:not(:disabled):not(.ant-btn-disabled):hover {
			background: $bgGrad;
			&::after {
				opacity: 1;
			}
		}

		& > span {
			font-size: 16px;
			font-weight: 400;
		}
		&:hover {
			color: $white;
		}
	}

	&__btns-wrap {
		display: flex;
		align-items: center;

		& > * {
			min-width: 32px;
			&:not(:last-child) {
				margin-right: 5px;
			}
		}
	}

	&__devices-wrap {
		margin-top: 25px;
	}
	&__btn-sm {
		padding: 4px 5px;
	}
	&__devices-title {
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 700;
	}
}
