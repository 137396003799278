.webrtc {
	flex: 1;
	width: 100%;
	position: relative;

	& iframe {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		border: none;
		position: absolute;
		top: 0;
		left: 0;
	}
	&__close {
		position: absolute;
		top: -15px;
		right: -15px;
		background: white;
		font-size: 25px;
		border-radius: 50%;
		padding: 5px;
		opacity: 0.3;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}
	&__btn__small {
		position: absolute;
		bottom: 5px;
		right: 5px;
		opacity: 0.3;

		&:hover {
			opacity: 1;
		}
	}
}
