.terminalSettings {
	&__content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 20px;
	}

	&__title {
		margin-bottom: 15px;
		font-weight: 600;
	}
	&__subtitle {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 10px;
	}

	&__config {
		display: flex;
		flex-direction: column;
		gap: 4px;
		margin-bottom: 40px;

		button {
			width: 250px;
		}
	}

	&__config-download {
		margin-bottom: 40px;
		max-height: 500px;
		overflow-y: auto;
	}
	&__rekalame-item {
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}

	&__inner {
		display: flex;
		max-width: 600px;
		align-items: center;

		& > *:not(:last-child) {
			margin-right: 15px;
		}
	}
	&__item {
		&:not(:last-child) {
			border-right: 1px solid $accent;
		}
	}
	&__list-active {
		font-size: 13px;
	}
	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
		margin: 10px 0;

		&-item {
			display: flex;
			justify-content: space-between;
			align-items: center;

			padding: 4px;
			border-radius: 4px;
			border: 1px solid $default;
		}
	}
	&__main {
		padding-bottom: 19px;
		border-bottom: 1px solid $accent;
		margin-bottom: 20px;
	}
	&__main-btn {
		margin-top: 20px;
	}
	&__config-device {
		&-item {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 40px;
		}
	}
	&__maintenance-title {
		margin-bottom: 15px;
	}
}
