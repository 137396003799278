.setting-printer {
	&__form {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__form-item {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	&__form-item-title {
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
	}

	&__btn {
		width: 300px;
		margin: auto;
	}
}
