.jornal {
	&__title {
		margin-bottom: 15px;
		font-weight: 600;
	}
	&__filter {
		margin-bottom: 30px;
	}
	&__filter-text {
		margin-bottom: 5px;
	}
}
