.administration {
	flex: 1;
	color: $white;

	&__title {
		color: $default;
		margin-bottom: 30px;
	}

	&__back {
		margin-bottom: 20px;
	}

	&__add-btn {
		margin-bottom: 30px;
	}

	&__search {
		margin-bottom: 30px;
	}
	&__tabs {
		& .ant-tabs-nav {
			margin-bottom: 0;
		}
	}
	&__inner {
		background-color: rgba(255, 255, 255, 0.7);
		backdrop-filter: blur(5px);
		border-radius: 0 20px 20px;
		padding: 20px;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	}
}
