.device {
	padding: 0 5px;
	position: relative;
	transition: box-shadow 0.3s ease;
	text-align: center;

	&__title {
		font-size: 16px;
		font-weight: 400;
		display: flex;
		align-items: center;
		margin-bottom: 1px;
		gap: 5px;

		& img {
			height: 25px;
		}
	}

	&__status {
		font-size: 15px;
		&--success {
			color: green;
		}
		&--error {
			color: red;
		}
	}
}
