.reports__tabs {
	.ant-tabs-nav {
		margin-bottom: 0;
	}
}

.reports {
	&__inner {
		background-color: rgba(255, 255, 255, 0.7);
		backdrop-filter: blur(5px);
		border-radius: 0 20px 20px;
		padding: 20px;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
	}
}
