.operators {

    flex: 1;

    &__back {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 30px;
        color: $default;
    }

    &__wrap {
        background-color: rgba(255, 255, 255, 0.7);
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 20px;
        padding: 20px;
        border: 1px solid $white;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 30px;
    }

    &__avatar {
        margin-right: 20px;
        max-width: 200px;
        flex-shrink: 0;
        width: 100%;
    }

    &__main {
        flex: 1;
    }

    &__btns {
        margin-left: 30px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
    }

    &__btn-pass-del {
        margin-bottom: 14px;
    }

    & .ant-upload-list-item-container {
        width: 200px !important;
        height: 200px !important;
    }

    & .ant-upload-list {
        flex-direction: column;
    }

    & .ant-upload.ant-upload-select {
        width: 100% !important;
        height: auto !important;
    }

    & img {
        object-fit: cover !important;
    }

    &__avatar-new {
        padding: 10px;
        display: flex;
        align-items: center;
    }

    &__btn-save {
        width: 400px;
        margin-top: 40px;
    }

    &__main {
        &>*:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__locations-select {
        width: 100% !important;
    }

    &__center {
        max-width: 400px;

        &>*:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__ban-wrap {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    &__ban-text {
        font-weight: 500;
        font-size: 16px;
        margin-right: 15px;
    }
}