.aside {
	position: sticky;
	top: 0;
	height: 100vh;
	z-index: 2;
	background: $bgGrad;
	color: $white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&._close {
		& .aside__img-wrap {
			margin: 0;
		}
		& .aside__link {
			justify-content: center;
		}
		& .aside__exit {
			padding: 15px;
			justify-content: center;
		}
		& .aside__link-icon {
			margin-right: 0;
			width: 28px;
			height: 28px;
		}
	}
	&__top-wrap {
		margin-bottom: 40px;
		@include adpt($big-r) {
			margin-bottom: 30px;
		}
	}
	&__top-status-text {
		font-size: 15px;
		font-weight: 500;
	}
	&__top-name {
		display: flex;
		align-items: flex-start;
	}
	&__top-status {
		margin-top: 15px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		gap: 5px 20px;
	}
	&__top {
		padding: 20px 24px;
		margin-bottom: 30px;
		border-bottom: 1px solid #9f9aca;
	}
	&__img-wrap {
		flex-shrink: 0;
		margin-right: 15px;
	}
	&__img {
		width: 50px;
		height: 50px;
		aspect-ratio: 60/60;
		object-fit: cover;
		@include adpt($big-r) {
			width: 40px;
			height: 40px;
		}
	}
	&__top-text {
		font-size: 22px;
	}
	&__bold-text {
		font-weight: 500;
		font-size: 14px;
		color: #b4c6fc;
		@include adpt($big-r) {
			font-size: 12px;
		}
	}
	&__text-name {
		margin-bottom: 2px;
		font-size: 16px;
		font-weight: 500;
		@include adpt($big-r) {
			font-size: 14px;
		}
	}

	&__link-icon {
		margin-right: 15px;
		width: 18px;
		height: 18px;
		object-fit: contain;
		@include adpt($big-r) {
			width: 16px;
			height: 16px;
		}
	}
	&__link {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		transition: all 0.3s;

		& .BeginPage__item-title {
			color: $white;
			font-size: 19px;
		}
		&.active {
			font-weight: 700;
		}
	}
	&__bottom {
		padding: 20px;
	}
	&__bottom-img-wrap {
		margin-bottom: 20px;
		@include adpt($big-r) {
			margin-bottom: 10px;
		}
	}
	&__bottom-text {
		text-transform: uppercase;
		font-size: 25px;
		text-align: center;
		font-weight: 500;
		@include adpt($big-r) {
			font-size: 22px;
		}
	}
	&__exit {
		background-color: red;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__toggle {
		position: absolute;
		right: -30px;
		top: 50%;
		z-index: 2;
		transform: translateY(-50%);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 60px;
		background: #4b72b8;
		border-radius: 0 5px 5px 0;
	}
	&__bottom-btn {
		width: 100%;
	}
}
