.ant-input-outlined:hover {
	border-color: $accent;
}
.ant-input {
	&:focus {
		border-color: #5c73db;
	}
}
.ant-btn {
	& > span {
		font-size: 16px;
	}
}
.ant-input-lg {
	border-radius: 12px;
	padding: 15px;
	font-size: 14px;
	line-height: 17px;
}
.ant-input-affix-wrapper-lg {
	border-radius: 12px;
	padding: 5px 15px;
	font-size: 14px;
	line-height: 17px;
	height: 48px;
}
.ant-btn-lg {
	height: 48px;
	& > span {
		font-size: 18px;
	}
}
.ant-btn-variant-solid {
	background: $bgGrad;
	background-size: 120%;
	position: relative;
	border: none;
	overflow: hidden;
	border-radius: 12px;

	& > * {
		position: relative;
		z-index: 1;
	}
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(0deg, #7497cd 15%, #0e4da4 100%);
		opacity: 0;
		z-index: 0;
		transition: opacity 0.3s;
	}
	&:not(:disabled):not(.ant-btn-disabled):hover {
		background: $bgGrad;
		&::after {
			opacity: 1;
		}
	}
}
.ant-btn-variant-outlined {
	border-color: #658cc7;
	color: #658cc7;
	font-size: 16px;
	border-radius: 10px;
	font-weight: 500;
	background-color: $white;
	&:not(:disabled):not(.ant-btn-disabled):hover {
		background-color: $accent;
		color: $white;
		border-color: $accent;
	}
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
	border-bottom-color: $accent;
	background: $accent;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
	border: 1px solid $accent;
	border-bottom: none;
}
.ant-btn-color-dangerous.ant-btn-variant-solid:not(:disabled):not(
		.ant-btn-disabled
	):hover {
	&::after {
		opacity: 0;
	}
}
