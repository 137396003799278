html,
body {
	height: 100%;
}

#root {
	height: 100%;
	display: flex;
	flex-direction: column;
}

body {
	font-family: $interFont;
	font-optical-sizing: auto;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3;
	background-color: $white;
	color: $default;
	overflow-x: hidden;
}

.container {
	padding: 0 50px;
}

.main {
	flex: 1;
	display: grid;
	grid-template-columns: 320px 1fr;
	@include adpt($big-r) {
		grid-template-columns: 266px 1fr;
	}
	&__right {
		position: relative;
		padding-left: 40px;
		padding-top: 30px;
		padding-bottom: 20px;
		padding-right: 30px;
		display: flex;
		flex-direction: column;
		background-color: #fafafa;
		@include adpt($big-r) {
			padding-top: 25px;
		}
	}
	&._aside-close {
		grid-template-columns: 100px 1fr;
	}
}

.back-btn {
	padding: 5px 10px;
	background-color: #fff;
	border: 1px solid $accent;
	color: $accent;
	font-weight: 700;
	border-radius: 10px;
	margin-bottom: 30px;
	@include adpt($big-r) {
		margin-bottom: 20px;
	}
}

.sr-only {
	position: absolute;
	width: 0px;
	height: 0px;
	margin: -1px;
	border: 0;
	padding: 0;
	white-space: nowrap;
	clip-path: inset(100%);
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.burger {
	width: 35px;
	height: 35px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

._fixed {
	overflow: hidden;
}

.section {
	padding: 70px 0;
	@include adpt($big-r) {
		padding: 50px 0;
	}
}

.inputs-wrap {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 15px;
	&__row {
		display: flex;
		& > * {
			flex: 1;
			&:not(:last-child) {
				margin-right: 15px;
			}
		}
	}
}

.btn-back {
	margin-bottom: 20px;
}

.btn {
	padding: 15px 50px;
	border-radius: 5px;
	cursor: pointer;
	background-color: $accent;
	color: $white;
	font-weight: 500;
	text-align: center;
	font-size: 15px;
	line-height: 1;
	border: 1px solid $accent;
	transition: all 0.3s;
	&:hover {
		background-color: $white;
		color: $accent;
	}
	&:disabled {
		opacity: 0.8;
		pointer-events: none;
	}
}

._h3 {
	font-weight: 700;
	font-size: 32px;
	line-height: 1.3;
	@include adpt($tab-r) {
		font-size: 28px;
	}
}

._h4 {
	font-size: 20px;
	font-weight: 700;
	@include adpt($tab-r) {
		font-size: 18px;
	}
}

.title {
	font-weight: 600;
	font-size: 36px;
	line-height: 1.2;
	color: $titleColor;
	margin-bottom: 30px;
}

.load {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	background-color: rgba(255, 255, 255, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	&__svg {
		width: 20%;
		aspect-ratio: 1/1;
		height: auto;
		object-fit: contain;
	}
}
._highlight {
	color: $accent;
	font-weight: 500;
	text-decoration: underline;
	transition: all 0.3s;
	&:hover {
		color: #62df9a;
	}
}
