.management {
  margin: 0 auto;
  display: inline-block;
  position: absolute;
  left: 10px;
  bottom: 10px;

  &__item {
    display: flex;
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
    & > * {
      &:not(:last-child) {
        margin-right: 45px;
      }
    }
  }
  &__btn {
    width: 40px !important;
    height: 40px;
    flex-shrink: 0;
    opacity: 0.3;
    & svg {
      width: 25px;
      height: 25px;
    }
    &:hover {
      opacity: 1;
    }
  }
}
