.terminal__pagination {
	margin-top: 20px;
	display: flex;
	justify-content: end;
	padding: 10px 0;
	.ant-pagination {
		background: linear-gradient(145deg, #ffffff, #f0f0f0);
		box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
		padding: 10px 20px;
		border-radius: 20px;
		.ant-pagination-item {
			border: none;
			background: transparent;
			transition: all 0.3s ease;
			&:hover {
				background: #4a90e2;
				color: #fff;
				border-radius: 5px;
			}
		}
		.ant-pagination-item-active {
			background: #4a90e2;
			color: #fff;
			border-radius: 5px;
			font-weight: bold;
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		}
		.ant-pagination-item-link {
			color: #4a90e2;
			background: transparent;
			transition: all 0.3s ease;
			&:hover {
				background: #4a90e2;
				color: #fff;
			}
		}
		.ant-pagination-prev,
		.ant-pagination-next {
			button {
				color: #4a90e2;
				border: none;
				background: transparent;
				transition: all 0.3s ease;
				&:hover {
					background: #4a90e2;
					color: #fff;
					border-radius: 5px;
				}
			}
		}
		.ant-pagination-options {
			display: flex;
			align-items: center;
			.ant-select {
				min-width: 80px;
				border-radius: 5px;
				.ant-select-selector {
					background: linear-gradient(145deg, #ffffff, #f0f0f0);
					box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
					border: none;
					&:hover {
						border-color: #4a90e2;
					}
				}
				.ant-select-arrow {
					color: #4a90e2;
				}
			}
		}
	}
}
